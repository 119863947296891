<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center mt-2">
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Detalhes do Grupo de Contratos</h5>

            <div class="row">
              <!-- Superior -->
              <div class="col-lg-5">
                <div class="row">
                  <div class="col-lg-12 px-0 pb-3">
                    <small class="custom-label">Nome</small>
                    <div class="text-capitalize">
                      {{ group.nome }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 px-0 pb-3">
                    <small class="custom-label">Descrição</small>
                    <div class="text-capitalize">
                      {{ group.observacao ? group.observacao : '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Contratos</small>
                    <div class="col-12 px-0 py-1" v-for="contrato in group.itens" :key="contrato.id">
                      {{ contrato.idcontrato_frete.numero_contrato }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <!-- Botões de ação -->
            <div class="row">
              <div class="col-12 px-0">
                <a v-if="canEdit" class="btn btn-sm btn-outline-light mx-1 pull-right"
                  v-bind:href="$util.mountLink('BookingContractGroupEdit', this.group)">Editar</a>

                <button type="button" class="btn btn-sm btn-outline-light mx-1 pull-right" @click="onCancel">
                  Listagem
                </button>

                <!-- <button type="button" class="btn btn-link btn-sm mx-1 pull-right" @click="mostra">
                  Console
                </button> -->

                <button type="button" class="btn btn-link btn-sm mx-1 pull-left" @click="onDelete">
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import BookingService from '@/services/BookingService'

export default {
  name: 'BookingContractGroupDetail',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      group: {
        nome: null,
        observacao: null,
        itens: []
      }
    }
  },
  // Apply filters to local filter
  beforeMount () {
    this.loadGroupInfo()
  },
  methods: {
    mostra () {
    },
    loadGroupInfo () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getBookingContractGroup(id).then(res => {
        this.group = res.data.data
        this.isLoading = false
      })
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingContractGroupIndex' })
    },
    onDelete () {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este registro?',
        'warning', 'Sim', 'Não', (e) => this.delete(this.booking.id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro excluído com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }

        this.$router.push({ name: 'BookingContractGroupIndex' })
      })
    },
    async delete (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.deleteBookingContractGroup(id).then(res => {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    canEdit () {
      return this.isSuperAdmin || this.isBookingAdmin
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

  .royalc-btn-close {
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #84754E;
    background: transparent;
  }

  .custom-label {
    color: grey;
  }
</style>
